<template>
  <section>
    <InvoiceModal :trip="invoiceModalDetails" :symbol="symbol" />

    <RefundModal paymentSourceEnabled @refunded="onRefunded" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <title-plus
              :title="$t('components.revenueManagement.headline')"
              :hide-plus="true"
            />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="$t('components.revenueManagement.summary.totalRevenue')"
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary.total_debit}`
            "
            variant="gray"
          />
          <SummaryCard
            :title="$t('components.revenueManagement.summary.netRevenue')"
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary.net_amount}`
            "
            variant="gray"
          />

          <SummaryCard
            :title="$t('components.revenueManagement.summary.avgRevenuePerDay')"
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary.avg_debit_per_day}`
            "
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.revenueManagement.summary.totalRefundAmount')
            "
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary
                .total_refunded_amount || 0}`
            "
            variant="gray"
          />
          <SummaryCard
            :title="$t('components.revenueManagement.summary.totalCardAmount')"
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary
                .total_card_amount || 0}`
            "
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.revenueManagement.summary.totalWalletAmount')
            "
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary
                .total_wallet_amount || 0}`
            "
            variant="gray"
          />
        </div>
      </div>
      <template v-if="$acl.canView('transaction')">
        <FSTable
          :qso="qso"
          :fst-id="fstId"
          :headers="getTableHeaders"
          :exportType="exportKey.REVENUE"
          :exportFromURL="true"
          :endpoint="endpoint"
          :currency-enabled="true"
          :currency-attributes="getCurrencyAttributes"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="getFilteredItems"
              />
            </keep-alive>
          </template>

          <template v-slot:default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow
                  :key="itemIndex"
                  @meta="(e) => (indexMetaData = e)"
                  :className="{
                    'fst-row': true,
                    'host-child-trip-row': item.child_invoices.length > 0,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem
                    v-if="isTripOrRentExist(item)"
                    text-fallback-always
                  >
                    <router-link
                      :to="{
                        name: item.rental ? 'VehicleRentalDetail' : 'ViewTrip',
                        params: {
                          id: `${item.rental ? item.rental : item.trip}`,
                        },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.id.substr(item.id.length - 5) }}
                    </router-link>

                    <span
                      v-if="item.child_invoices.length > 0"
                      class="bg-blue-600 child-invoice-index-badge "
                      >E</span
                    >
                  </FSTableRowItem>
                  <FSTableRowItem v-else>
                    {{ item.id.substr(item.id.length - 5) }}
                    <span
                      v-if="item.child_invoices.length > 0"
                      class="bg-blue-600 child-invoice-index-badge "
                      >E</span
                    >
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="item.tax_plan"
                    :text-fallback="`--`"
                    :truncate="-5"
                  />

                  <FSTableRowItem>
                    <router-link
                      v-if="item.user"
                      :to="{
                        name: 'ViewRiderUserProfile',
                        params: { id: item.user.id },
                      }"
                      class="font-extrabold text-blue-600 capitalize"
                      target="_blank"
                    >
                      {{ item.user.full_name }}
                    </router-link>
                    <span v-else>
                      --
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <template v-if="item.user">
                      {{ item.user.role_name }}
                    </template>
                    <template v-else>
                      --
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.date" :date="true" />

                  <FSTableRowItem
                    v-if="!item.payment_method_details"
                    :text="item.payment_method"
                  />

                  <FSTableRowItem v-if="item.payment_method_details">
                    <i
                      :class="
                        getPaymentCardIconClass(item.payment_method_details)
                      "
                      v-if="item.payment_method && item.payment_method !== '--'"
                    />
                    <span
                      v-if="item.payment_method && item.payment_method !== '--'"
                      >&nbsp;&nbsp;</span
                    >
                    {{ item.payment_method }}
                  </FSTableRowItem>
                  <FSTableRowItem :text="getPaymentForText(item.payment_for)" />
                  <FSTableRowItem :text="item" as-currency />

                  <FSTableRowItem
                    :text="item.payment_reference_id"
                    :text-fallback="`--`"
                  >
                    <template #default="{ text }">
                      <span v-if="text === '--'">--</span>
                      <span v-else>
                        <span
                          v-if="paymentMethod === 'Stripe'"
                          class="text-blue-500"
                        >
                          <a
                            target="_blank"
                            :href="
                              `https://dashboard.stripe.com/payments/${text}`
                            "
                            >{{ truncate(text, -5, '') }}</a
                          >
                          <span
                            class="cursor-pointer text-gray-500"
                            @click="
                              copyTextToClipboard(
                                `https://dashboard.stripe.com/payments/${text}`
                              )
                            "
                          >
                            <i class="ml-2 far fa-copy"></i>
                          </span>
                        </span>
                        <span v-else>{{ truncate(text, -5, '') }}</span>
                      </span>
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <OtoEyeIcon
                      v-tooltip.bottom="'View Invoice'"
                      @click="onInvoiceModalReq(item.trip, false)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem class="flex gap-1">
                    <div
                      v-tooltip.bottom="
                        getRefundTooltip(item, { ignoreSource: true })
                      "
                      class="refund-action"
                      :class="item.is_refunded ? 'refunded' : 'refund'"
                      @click="showRefundPopup(item)"
                    >
                      {{
                        item.is_refunded
                          ? $t('components.revenueManagement.actions.refunded')
                          : $t('components.revenueManagement.actions.refund')
                      }}
                    </div>
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :id="`more-actions-${itemIndex}`"
                      :data="item"
                      @delete="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow
                  v-for="(childItem, childItemIndex) in item.child_invoices"
                  :key="`child-${itemIndex}-${childItemIndex}`"
                  :className="`fst-row child-trip-row`"
                  text-fallback-always
                >
                  <FSTableRowItem v-if="isTripOrRentExist(childItem)">
                    <router-link
                      :to="{
                        name: childItem.rental
                          ? 'VehicleRentalDetail'
                          : 'ViewTrip',
                        params: {
                          id: `${
                            childItem.rental ? childItem.rental : childItem.trip
                          }`,
                        },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ childItem.id.substr(childItem.id.length - 5) }}
                    </router-link>

                    <span class="bg-gray-400 child-invoice-index-badge "
                      >{{ childItemIndex + 1 }}
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem v-else>
                    {{ childItem.id.substr(childItem.id.length - 5) }}
                    <span class="bg-gray-400 child-invoice-index-badge "
                      >{{ childItemIndex + 1 }}
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="childItem.tax_plan"
                    :text-fallback="`--`"
                    :truncate="-5"
                  />

                  <FSTableRowItem>
                    <router-link
                      v-if="childItem.user"
                      :to="{
                        name: 'ViewRiderUserProfile',
                        params: { id: childItem.user.id },
                      }"
                      class="font-extrabold text-blue-600 capitalize"
                      target="_blank"
                    >
                      {{ childItem.user.full_name }}
                    </router-link>
                    <span v-else>
                      --
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <template v-if="childItem.user">
                      {{ childItem.user.role_name }}
                    </template>
                    <template v-else>
                      --
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem :text="childItem.date" :date="true" />

                  <FSTableRowItem
                    v-if="!childItem.payment_method_details"
                    :text="childItem.payment_method"
                  />

                  <FSTableRowItem v-if="childItem.payment_method_details">
                    <i
                      :class="
                        getPaymentCardIconClass(
                          childItem.payment_method_details
                        )
                      "
                      v-if="
                        childItem.payment_method &&
                          childItem.payment_method !== '--'
                      "
                    />
                    <span
                      v-if="
                        childItem.payment_method &&
                          childItem.payment_method !== '--'
                      "
                      >&nbsp;&nbsp;</span
                    >
                    {{ childItem.payment_method }}
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="getPaymentForText(childItem.payment_for)"
                  />
                  <FSTableRowItem :text="childItem" as-currency />

                  <FSTableRowItem
                    :text="childItem.payment_reference_id"
                    :text-fallback="`--`"
                  >
                    <template #default="{ text }">
                      <span v-if="text === '--'">--</span>
                      <span v-else>
                        <span
                          v-if="paymentMethod === 'Stripe'"
                          class="text-blue-500"
                        >
                          <a
                            target="_blank"
                            :href="
                              `https://dashboard.stripe.com/payments/${text}`
                            "
                            >{{ truncate(text, -5, '') }}</a
                          >
                          <span
                            class="cursor-pointer text-gray-500"
                            @click="
                              copyTextToClipboard(
                                `https://dashboard.stripe.com/payments/${text}`
                              )
                            "
                          >
                            <i class="ml-2 far fa-copy"></i>
                          </span>
                        </span>
                        <span v-else>{{ truncate(text, -5, '') }}</span>
                      </span>
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <OtoEyeIcon
                      v-tooltip.bottom="'View Invoice'"
                      @click="onInvoiceModalReq(childItem.trip, false)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem class="flex gap-1">
                    <div
                      v-tooltip.bottom="
                        getRefundTooltip(childItem, { ignoreSource: true })
                      "
                      class="refund-action"
                      :class="childItem.is_refunded ? 'refunded' : 'refund'"
                      @click="showRefundPopup(childItem)"
                    >
                      {{ childItem.is_refunded ? 'Refunded' : 'Refund' }}
                    </div>
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}-${childItemIndex}`"
                      :id="`more-actions-${itemIndex}-${childItemIndex}`"
                      :data="item"
                      @delete="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  @meta="(e) => (indexMetaData = e)"
                  :className="{
                    'fst-row': true,
                    'host-child-trip-row': item.child_invoices.length > 0,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem
                    v-if="isTripOrRentExist(item)"
                    text-fallback-always
                  >
                    <router-link
                      :to="{
                        name: item.rental ? 'VehicleRentalDetail' : 'ViewTrip',
                        params: {
                          id: `${item.rental ? item.rental : item.trip}`,
                        },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.id.substr(item.id.length - 5) }}
                    </router-link>

                    <span
                      v-if="item.child_invoices.length > 0"
                      class="bg-blue-600 child-invoice-index-badge "
                      >E</span
                    >
                  </FSTableRowItem>
                  <FSTableRowItem v-else>
                    {{ item.id.substr(item.id.length - 5) }}
                    <span
                      v-if="item.child_invoices.length > 0"
                      class="bg-blue-600 child-invoice-index-badge "
                      >E</span
                    >
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="!item.payment_method_details"
                    :text="item.payment_method"
                  />

                  <FSTableRowItem v-if="item.payment_method_details">
                    <i
                      :class="
                        getPaymentCardIconClass(item.payment_method_details)
                      "
                      v-if="item.payment_method && item.payment_method !== '--'"
                    />
                    <span
                      v-if="item.payment_method && item.payment_method !== '--'"
                      >&nbsp;&nbsp;</span
                    >
                    {{ item.payment_method }}
                  </FSTableRowItem>

                  <FSTableRowItem :text="item" as-currency />
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.revenueManagement.table.columns.taxID')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ truncate(item.tax_plan, -5, '#') || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.revenueManagement.table.columns.rider')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <router-link
                          v-if="item.user"
                          :to="{
                            name: 'ViewRiderUserProfile',
                            params: { id: item.user.id },
                          }"
                          class="font-extrabold text-blue-600 capitalize"
                          target="_blank"
                        >
                          {{ item.user.full_name }}
                        </router-link>
                        <span v-else>
                          --
                        </span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.revenueManagement.table.columns.payerRole'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.user.role_name || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.revenueManagement.table.columns.transactionTime'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.date,
                            'D MMM, YY hh:mm:ss a'
                          ) || `--`
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.revenueManagement.table.columns.paymentFor'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ getPaymentForText(item.payment_for) }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.revenueManagement.table.columns.paymentRef'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <span v-if="item.payment_reference_id">
                          <span
                            v-if="paymentMethod === 'Stripe'"
                            class="text-blue-500"
                          >
                            <a
                              target="_blank"
                              :href="
                                `https://dashboard.stripe.com/payments/${item.payment_reference_id}`
                              "
                              >{{
                                truncate(item.payment_reference_id, -5, '')
                              }}</a
                            >
                            <span
                              class="cursor-pointer text-gray-500"
                              @click="
                                copyTextToClipboard(
                                  `https://dashboard.stripe.com/payments/${item.payment_reference_id}`
                                )
                              "
                            >
                              <i class="ml-2 far fa-copy"></i>
                            </span>
                          </span>
                          <span v-else>{{
                            truncate(item.payment_reference_id, -5, '')
                          }}</span>
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.revenueManagement.table.columns.invoice'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <OtoEyeIcon
                          v-tooltip.bottom="'View Invoice'"
                          @click="onInvoiceModalReq(item.trip, false)"
                        />
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.revenueManagement.table.columns.actions'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex gap-1">
                          <div
                            v-tooltip.bottom="
                              getRefundTooltip(item, { ignoreSource: true })
                            "
                            class="refund-action"
                            :class="item.is_refunded ? 'refunded' : 'refund'"
                            @click="showRefundPopup(item)"
                          >
                            {{ item.is_refunded ? 'Refunded' : 'Refund' }}
                          </div>
                          <MoreActionsDropdown
                            :key="`more-actions-${itemIndex}-${childItemIndex}`"
                            :id="`more-actions-${itemIndex}-${childItemIndex}`"
                            :data="item"
                            @delete="$store.dispatch('fsTable/fetchData')"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
                <template
                  v-for="(childItem, childItemIndex) in item.child_invoices"
                >
                  <FSTableRow
                    :key="`child-${itemIndex}-${childItemIndex}`"
                    :className="`fst-row child-trip-row`"
                    text-fallback-always
                  >
                    <FSTableRowItem>
                      <div
                        class="col-span-1 focus:text-gray-400"
                        @click="toggle(`${itemIndex}${childItemIndex}`)"
                      >
                        <i
                          class="fas fa-minus-circle"
                          style="color:#d90a20;"
                          v-if="
                            opened.includes(`${itemIndex}${childItemIndex}`)
                          "
                        ></i>
                        <i class="fas fa-plus-circle" v-else></i>
                      </div>
                    </FSTableRowItem>
                    <FSTableRowItem
                      v-if="isTripOrRentExist(childItem)"
                      text-fallback-always
                    >
                      <router-link
                        :to="{
                          name: childItem.rental
                            ? 'VehicleRentalDetail'
                            : 'ViewTrip',
                          params: {
                            id: `${
                              childItem.rental
                                ? childItem.rental
                                : childItem.trip
                            }`,
                          },
                        }"
                        :class="`text-blue-600`"
                        target="_blank"
                      >
                        {{ childItem.id.substr(childItem.id.length - 5) }}
                      </router-link>

                      <span class="bg-gray-400 child-invoice-index-badge "
                        >{{ childItemIndex + 1 }}
                      </span>
                    </FSTableRowItem>
                    <FSTableRowItem v-else>
                      {{ childItem.id.substr(childItem.id.length - 5) }}
                      <span class="bg-gray-400 child-invoice-index-badge "
                        >{{ childItemIndex + 1 }}
                      </span>
                    </FSTableRowItem>

                    <FSTableRowItem
                      v-if="!childItem.payment_method_details"
                      :text="childItem.payment_method"
                    />

                    <FSTableRowItem v-if="childItem.payment_method_details">
                      <i
                        :class="
                          getPaymentCardIconClass(
                            childItem.payment_method_details
                          )
                        "
                        v-if="
                          item.payment_method && item.payment_method !== '--'
                        "
                      />
                      <span
                        v-if="
                          childItem.payment_method &&
                            childItem.payment_method !== '--'
                        "
                        >&nbsp;&nbsp;</span
                      >
                      {{ childItem.payment_method }}
                    </FSTableRowItem>

                    <FSTableRowItem :text="childItem" as-currency />
                  </FSTableRow>
                  <FSTableRow
                    v-if="opened.includes(`${itemIndex}${childItemIndex}`)"
                    :key="childItemIndex"
                  >
                    <td colspan="10">
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.taxID'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{ truncate(childItem.tax_plan, -5, '#') || `--` }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.rider'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          <router-link
                            v-if="childItem.user"
                            :to="{
                              name: 'ViewRiderUserProfile',
                              params: { id: childItem.user.id },
                            }"
                            class="font-extrabold text-blue-600 capitalize"
                            target="_blank"
                          >
                            {{ childItem.user.full_name }}
                          </router-link>
                          <span v-else>
                            --
                          </span>
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.payerRole'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{ childItem.user.role_name || `--` }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.transactionTime'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{
                            getFormattedDateTime(
                              childItem.date,
                              'D MMM, YY hh:mm:ss a'
                            ) || `--`
                          }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.paymentFor'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{ getPaymentForText(childItem.payment_for) }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.paymentRef'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          <span v-if="childItem.payment_reference_id">
                            <span
                              v-if="paymentMethod === 'Stripe'"
                              class="text-blue-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  `https://dashboard.stripe.com/payments/${childItem.payment_reference_id}`
                                "
                                >{{
                                  truncate(
                                    childItem.payment_reference_id,
                                    -5,
                                    ''
                                  )
                                }}</a
                              >
                              <span
                                class="cursor-pointer text-gray-500"
                                @click="
                                  copyTextToClipboard(
                                    `https://dashboard.stripe.com/payments/${childItem.payment_reference_id}`
                                  )
                                "
                              >
                                <i class="ml-2 far fa-copy"></i>
                              </span>
                            </span>
                            <span v-else>{{
                              truncate(childItem.payment_reference_id, -5, '')
                            }}</span>
                          </span>
                          <span v-else>--</span>
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.invoice'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          <OtoEyeIcon
                            v-tooltip.bottom="'View Invoice'"
                            @click="onInvoiceModalReq(childItem.trip, false)"
                          />
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.revenueManagement.table.columns.actions'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          <div class="flex gap-1">
                            <div
                              v-tooltip.bottom="
                                getRefundTooltip(childItem, {
                                  ignoreSource: true,
                                })
                              "
                              class="refund-action"
                              :class="
                                childItem.is_refunded ? 'refunded' : 'refund'
                              "
                              @click="showRefundPopup(childItem)"
                            >
                              {{
                                childItem.is_refunded
                                  ? $t(
                                      'components.revenueManagement.actions.refunded'
                                    )
                                  : $t(
                                      'components.revenueManagement.actions.refund'
                                    )
                              }}
                            </div>
                            <MoreActionsDropdown
                              :key="
                                `more-actions-${itemIndex}-${childItemIndex}`
                              "
                              :id="
                                `more-actions-${itemIndex}-${childItemIndex}`
                              "
                              :data="item"
                              @delete="$store.dispatch('fsTable/fetchData')"
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </FSTableRow>
                </template>
              </template>
            </template>
          </template>
        </FSTable>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        {{ $t('components.acl.doNotHavePermission') }}
      </div>
    </content-section>
  </section>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { TransactionConfig } from '@/config/TransactionConfig'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
  generateCurrencyAttributes,
} from '@/components/fs-table'

import MoreActionsDropdown from '@/views/transactions/MoreActionsDropdown.vue'
import { useEndpoints } from '@/composables'
import { truncate } from '@/plugins/truncate'
import { getDisputeStatus, resolveProp } from '@/utils'
import { getRefundTooltip } from '@/composites/transaction/shared/refund-helpers'
import { getFormattedDateTime } from '@/utils/datetime'
import { exportKey } from '@/utils/export-data/helper.js'
export default {
  name: 'Transactions',
  props: {
    pageTitle: {
      type: String,
      default: 'Revenues',
    },
    fstId: {
      type: String,
      default: 'revenues',
    },
    endpoint: {
      type: String,
      default: useEndpoints.invoice.indexOfRevenue(),
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '' }),
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    RefundModal: () => import('./RefundPopup.vue'),
    InvoiceModal: () => import('@/components/modals/InvoiceModal'),
    MoreActionsDropdown,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
  },
  data() {
    return {
      exportKey,
      windowWidth: window.innerWidth,
      symbol: '$',
      indexMetaData: {
        summary: {
          currency: {
            symbol: '',
          },
          total_debit: '0.00',
          net_amount: '0.00',
          avg_debit_per_day: '0.00',
          total_refunded_amount: '0.00',
          total_card_amount: '0.00',
          total_wallet_amount: '0.00',
        },
        count: {
          total: 0,
        },
      },
      // indexDataEndpoint: TransactionConfig.api.index,

      tableHeaders: [
        {
          text: this.$t('components.revenueManagement.table.columns.invoiceID'),
          width: '7%',
          sort: 'id',
        },
        {
          text: this.$t('components.revenueManagement.table.columns.taxID'),
          width: '7%',
          sort: 'tax',
        },
        {
          text: this.$t('components.revenueManagement.table.columns.rider'),
          width: '10%',
          sort: 'user',
        },
        {
          text: this.$t('components.revenueManagement.table.columns.payerRole'),
          width: '9%',
          sort: 'user',
        },
        {
          text: this.$t(
            'components.revenueManagement.table.columns.transactionTime'
          ),
          width: '13%',
          sort: 'date',
        },
        {
          text: this.$t(
            'components.revenueManagement.table.columns.paymentMethod'
          ),
          width: '12%',
          sort: 'payment',
        },
        {
          text: this.$t(
            'components.revenueManagement.table.columns.paymentFor'
          ),
          width: '8%',
          sort: 'payment_for',
        },
        {
          text: this.$t('components.revenueManagement.table.columns.amount'),
          width: '8%',
          sort: 'amount',
        },
        {
          text: this.$t(
            'components.revenueManagement.table.columns.paymentRef'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.revenueManagement.table.columns.invoice'),
          width: '5%',
          sort: null,
        },
        {
          text: this.$t('components.revenueManagement.table.columns.actions'),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.revenueManagement.table.columns.invoiceID'),
          width: '25%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.revenueManagement.table.columns.paymentMethod'
          ),
          width: '35%',
          sort: 'payment',
        },
        {
          text: this.$t('components.revenueManagement.table.columns.amount'),
          width: '30%',
          sort: 'amount',
        },
      ],
      opened: [],

      invoiceModalState: false,
      invoiceModalDetails: {},

      // generated by generateFilterOptions()
      filterOptions: [],
      paymentMethod: '',
    }
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getEndpoint() {
      return useEndpoints.invoice.indexOfRevenue()
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },

    getCurrencyAttributes() {
      return generateCurrencyAttributes({
        root: '',
        // def: {
        //   amount: 'settled_amount',
        //   currencyName: 'settled_currency.name',
        //   currencySymbol: 'settled_currency.symbol',
        // },
        def: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
        actual: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
      })
    },
  },

  async created() {
    this.symbol = this.$org?.default_currency?.symbol || '$'
    await this.generateFilterOptions()
    const allPaymentGateways = await this.$http
      .get(useEndpoints.dropdown.paymentGateways())
      .then((res) => res.data.data)
      .catch((err) => console.log('paymentGatewayErr', err))
    const selectedPaymentGateway = allPaymentGateways.find(
      (pgItem) =>
        pgItem.id ===
        JSON.parse(localStorage.getItem('organization'))
          .default_payment_gateway_config.payment_gateway
    )
    this.paymentMethod = selectedPaymentGateway.name
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    getDisputeStatus,
    getRefundTooltip,
    truncate,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    isTripOrRentExist(item) {
      return !!item.trip || !!item.rental
    },
    copyTextToClipboard(link) {
      const el = document.createElement('textarea')
      el.value = link
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-99999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Payment Reference link Copied',
          text: 'The Payment Reference link has been copied to your clipboard',
        },
        2000
      )
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    formatDisputeReason(text) {
      if (!text) return ''
      // convert snake case to sentence
      return text
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },

    popup(item) {
      EventBus.$emit(TransactionConfig.events.viewingData, item)
      this.$modal.show(TransactionConfig.events.viewingData)
    },
    showRefundPopup(item) {
      if (this.$acl.canNotUpdate('transaction')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'transaction',
          })
        )
        return
      }

      if (item.is_refunded) {
        return
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, item)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    printAmount(type, amount) {
      let color = type === 'Card' ? 'text-oGreen' : ''
      return `<span class="${color}">${this.symbol} ${
        amount ? parseFloat(amount).toFixed(2) : '0.00'
      }</span>`
    },
    async onRefunded() {
      await this.$store.dispatch('fsTable/fetchData')
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Refunded',
          text: 'Successfully refunded to the rider.',
        },
        3000
      )
    },
    async onInvoiceModalReq(id, downloadOnly = false) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing`,
          text: 'The action is being executed...',
        },
        600
      )

      await this.$http
        .get(this.$config.trip.api.single(id), {
          params: {
            preferred_currency: resolveProp(
              'fsTable.organizationCurrency.selected',
              this.$store.state
            ),
          },
        })
        .then((res) => {
          this.invoiceModalDetails = res.data

          if (downloadOnly === true) {
            if (this.$refs.invoiceModal) {
              this.$refs.invoiceModal.exportToPDF()
            }
          } else {
            this.$modal.show('invoiceModal')
          }
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },
    async generateFilterOptions() {
      let filters = [
        {
          key: 'payment_type',
          type: 'checkbox',
          input: [
            { text: 'Trip', value: 'Trip' },
            { text: 'Card Trip', value: 'Trip With Card' },
            { text: 'Reservation', value: 'Reservation' },
            { text: 'Pass Trip', value: 'Pass Trip' },
            { text: 'Balance deduct by admin', value: 'Rider Balance Adjust' },
          ],

          title: 'Payment Type',
        },
        {
          key: 'payment_source',
          type: 'checkbox',
          input: [
            { text: 'Card', value: 'Card' },
            { text: 'eWallet', value: 'eWallet' },
            { text: 'Subscription Credit/Pass', value: 'Pass' },
          ],

          title: 'Payment Source',
        },
        {
          key: 'refund_status',
          type: 'checkbox',
          input: [{ text: 'Refunded', value: 'refunded' }],

          title: 'Refund Status',
        },
        {
          type: 'checkbox',
          key: 'exclude',
          input: [
            { text: 'Test Rider', value: 'test-user' },
            { text: 'Refunded', value: 'refunded' },
          ],
          title: 'Exclude',
        },
      ]

      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/?dropdown').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    getPaymentForText(textData) {
      if (textData.isArray) {
        return textData
      } else {
        const filterMap = [
          { text: 'Subscription Credit/Pass', value: 'Pass' },
          { text: 'Card Trip', value: 'Trip With Card' },
          { text: 'Balance deduct by admin', value: 'Rider Balance Adjust' },
        ]
        const selectedFilter = filterMap.filter(
          (item) => item.value === textData
        )
        if (selectedFilter.length > 0) {
          return selectedFilter[0].text
        } else {
          return textData
        }
      }
    },
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
  },
}
</script>

<style lang="scss" scoped>
.child-invoice-index-badge {
  border-radius: 50%;
  padding: 2px;
  margin-left: 8px;
  color: #fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    color: #5f72bf;
    background-color: #d7ebff;
  }

  &.disabled {
    background-color: #bebebe;
    color: #ffffff;
  }
}
</style>
